import { useRouter } from 'next/router';
import { ROUTES } from '@core/config';
import { AuthGuard } from '@core/ui/guard';

function Content() {
  const { replace } = useRouter();

  replace(ROUTES.organisations.root);
  return null;
}

export default function Index() {
  return (
    <AuthGuard>
      <Content />
    </AuthGuard>
  );
}
