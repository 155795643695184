import { ReactNode, useEffect } from 'react';
import { useRouter } from 'next/navigation';
import { useAuth } from '@core/auth';
import { ROUTES } from '@core/config';
import LoadingScreen from '@core/ui/components/loading-screen';

interface Props {
  children: ReactNode;
}

export default function PublicGuard({ children }: Props) {
  const { push } = useRouter();
  const { user, initialized } = useAuth();

  useEffect(() => {
    if (user) {
      push(ROUTES.root);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  if (!initialized || user) {
    return <LoadingScreen />;
  }

  return <> {children} </>;
}
