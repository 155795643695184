import { ReactNode } from 'react';
import { useAuth } from '@core/auth';
import { useRouter } from 'next/navigation';
import { ROUTES } from '@core/config';
import LoadingScreen from '@core/ui/components/loading-screen';

interface Props {
  skipOnboardingCheck?: boolean;
  children: ReactNode;
}

export default function AuthGuard({ skipOnboardingCheck, children }: Props) {
  const { initialized, user } = useAuth();
  const { replace } = useRouter();

  if (!initialized) {
    return <LoadingScreen />;
  }

  if (!user) {
    replace(ROUTES.signIn);
    return null;
  }

  if (!skipOnboardingCheck) {
    if (!user.firstName || !user.lastName) {
      replace(ROUTES.onboarding);
      return null;
    }
  }

  return <> {children} </>;
}
